import React from "react"
import { graphql, StaticQuery } from 'gatsby'

import './font-list.scss'

import FontTeaser from './font-teaser/font-teaser'

const FontList = ({ data }) => {
  const { edges: fonts } = data.allMarkdownRemark;

  return (
    <div className="font-list">
      {fonts &&
        fonts.map(({ node: font }, i) => (
          <FontTeaser
            key={ i }
            url={ font.frontmatter.path }
            text={ font.frontmatter.title }
            availableWeights={ [400, 500, 600] }
            />
        ))
      }
    </div>
  );
}


export default () => (
  <StaticQuery 
    query={graphql`
      query FontListQuery {
        allMarkdownRemark(
          sort: {order: DESC, fields: frontmatter___date},
          filter: {frontmatter: {content_type: {eq: "font"}}}
        ) {
          edges {
            node {
              frontmatter {
                title
                path
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <FontList data={data} />}
  />
)
