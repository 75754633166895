import React from "react";

import Layout from "../components/layout";
import FontList from "../components/font-list/font-list";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <FontList />
  </Layout>
);

export default IndexPage;
