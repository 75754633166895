import React from "react"

import "./font-teaser.scss"
import { useState } from "react";

const FontTeaser = ({ url, text, availableWeights }) => {
  const [currentWeight, setCurrentWeight] = useState(500);
  const textShort = text.replace(/\s+/g, '-').toLowerCase();

  const handleHover = () => {
    const randomWeight = availableWeights[Math.floor(Math.random() * availableWeights.length)];
    if (randomWeight !== currentWeight) {
      setCurrentWeight(randomWeight);
    } else {
      handleHover();
    }
  }

  return (
    <div className={`font-teaser font-teaser--${textShort}`}>
      <a href={ url } className="font-teaser__link" onMouseEnter={ handleHover } aria-label={ text }>
        <h2 className="font-teaser__text" style={{fontWeight: currentWeight}} >{ text }</h2>
      </a>
    </div>
  );
}

export default FontTeaser;